import { Booking } from '@wix/ambassador-bookings-server/types';
import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  CalendarViewModel,
  createMemoizedCalendarViewModelFactory,
} from './ViewModel/viewModel';
import { CalendarActions, createCalendarActions } from './Actions/actions';
import { Service } from '@wix/bookings-uou-types';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utils/context/contextFactory';
import { CalendarApi } from '../../api/CalendarApi';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { createInitialState } from '../../utils/state/initialStateFactory';
import { BottomSectionStatus } from './ViewModel/widgetViewModel/widgetViewModel';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { TimePickerStatus } from './ViewModel/timePickerViewModel/timePickerViewModel';
import { CalendarErrors, TriggeredByOptions } from '../../utils/bi/consts';
import {
  DialogState,
  DialogType,
} from './ViewModel/dialogViewModel/dialogViewModel';
import { SelectedBookingPreference } from '../../utils/bookingPreferences/bookingPreferences';
import { FlowElements } from './Hooks/useFlow';
import { EmptyStateType } from './ViewModel/emptyStateViewModel/emptyStateViewModel';
import { FilterOptions } from '../../types/types';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  bottomSectionStatus: BottomSectionStatus;
  timePickerStatus: TimePickerStatus;
  selectedService: Service | undefined;
  selectedDate?: string;
  selectedTimezone?: string;
  selectedMonth?: string;
  selectedTime?: string;
  selectableSlotsAtSelectedTime?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  availableSlotsPerDay?: QueryAvailabilityResponse;
  selectedBookingPreferences: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
  dialog?: {
    type: DialogType;
    state: DialogState;
  };
  filterOptions: FilterOptions;
  focusedElement?: FlowElements;
  initialErrors: EmptyStateType[];
};

// For more info about controller structure,
// check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  let rerender: () => Promise<void> = async () => {};
  return {
    async pageReady() {
      const {
        controllerConfig,
        reportError,
        translations: { t },
      } = flowAPI;
      const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
      const calendarApi = new CalendarApi({ wixSdkAdapter, reportError });

      const initialErrors: EmptyStateType[] = [];

      const onError = (type: EmptyStateType) => initialErrors.push(type);

      const [
        catalogDate,
        rescheduleBookingDetails,
        isPricingPlanInstalled,
      ] = await Promise.all([
        calendarApi.getCatalogData({ onError }),
        calendarApi.getBookingDetails({ onError }),
        wixSdkAdapter.isPricingPlanInstalled().catch(()=> false)
      ]);

      const initialState: CalendarState = createInitialState({
        service: catalogDate?.services?.[0],
        staffMembers: catalogDate?.staffMembers,
        wixSdkAdapter,
        rescheduleBookingDetails,
        initialErrors,
      });

      const calendarContext: CalendarContext = createCalendarContext({
        flowAPI,
        businessInfo: catalogDate?.businessInfo,
        activeFeatures: catalogDate?.activeFeatures,
        calendarApi,
        wixSdkAdapter,
        initialState,
        isPricingPlanInstalled,
      });

      const { onStateChange, render } = await createControlledComponent<
        CalendarState,
        CalendarActions,
        CalendarViewModel,
        CalendarContext
      >({
        controllerConfig,
        initialState,
        viewModelFactory: createMemoizedCalendarViewModelFactory(
          wixSdkAdapter.isEditorMode(),
        ),
        actionsFactory: createCalendarActions,
        context: calendarContext,
      });
      rerender = render;

      const { biLogger } = calendarContext;

      biLogger.bookingsCalendarFiltersLoad({
        triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
        selectedFilters: JSON.stringify(initialState.filterOptions),
      });
      if (!wixSdkAdapter.isSSR()) {
        onStateChange((state) => {
          biLogger.update(state);
        });
      }
    },
    updateConfig() {
      rerender();
    },
  };
};

export default createController;
